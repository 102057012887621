import React from "react"
import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"

export default function Template({
  data
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO title={frontmatter.title} />

      <div className="animated fadeIn blog-post-container">
        <div className="blog-post">
          <div style={{ display: "flex" }}>
            {/* <div>
              <button style={{ marginRight: 16 }} className="btn-round" onClick={() => navigate("/")}><i className="material-icons">arrow_back_ios</i></button>
            </div> */}
            <div>
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
          {/* <h2>{frontmatter.date}</h2> */}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`